<template>
  <div
    class="modal fade"
    id="addCatatan"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <form @submit.prevent="onSubmitRekonsiliasi">
            <div class="modal-header">
              <div class="modal-title text-center">{{ !formData.detailcatatan ? 'Tambah Catatan' : 'Catatan' }}</div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <div class="row">
                  <div class="col-4">
                    <label>Catatan</label>
                  </div>
                  <div class="col-8">
                    <textarea
                      v-model="formData.catatan_rekonsiliasi"
                      class="form-control"
                      placeholder="Masukkan catatan"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" v-if="!formData.detailcatatan">
              <!-- <button type="button" class="btn btn-close" data-dismiss="modal">Batal</button> -->
              <button type="submit" :disabled="isSubmit" class="btn btn-save">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex mb-4 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">{{ labelPage }}</h4>
          </div>
          <!-- <form class="search mr-3" @submit.prevent="onSearch">
            <input
              v-model="search"
              type="text"
              @keyup="searchCheck"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form> -->
        </div>
        <div class="card-table">
          <div class="row">
            <div class="col-10">
              <div class="d-flex mb-4">
                <Select2
                  v-model="formFilter.id_akun"
                  :options="OptionAccount"
                  placeholder="Pilih Tipe Akun"
                  style="width: 200px"
                  :settings="{ templateResult: formatState }"
                  @change="myChangeEvent($event)"
                  @select="myChangeEvent($event)"
                />

                <Datepicker
                  :autoApply="true"
                  v-model="formFilter.date"
                  :enableTimePicker="false"
                  locale="id-ID"
                  :range="true"
                  :format="formatRange"
                  class="ml-3"
                  selectText="Pilih"
                  cancelText="Batal"
                  placeholder="Pilih Tanggal"
                  :max-date="new Date().setDate(new Date().getDate() - 1)"
                  style="width: 300px"
                ></Datepicker>
                <Select2
                  v-model="formFilter.status"
                  :options="OptionStatus"
                  placeholder="Pilih Status"
                  style="width: 200px"
                  class="ml-3"
                  @change="myChangeEvent($event)"
                  @select="myChangeEvent($event)"
                />
                <button class="btn-add ml-3" style="background-color: #0597df !important; border-color: #0597df !important" @click="getList">Tampilkan</button>
              </div>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-end">
              <router-link
                :to="{
                  name: 'InsertGeneralJournal'
                }"
                class="btn-add"
                style="height: 42px"
                v-if="
                  checkAccess('kas_bank') ||
                  checkAccess('kas_bank', 'cru_jurnal_general')
                "
                >Jurnal
              </router-link>
            </div>

        </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableRekonsiliasiBank"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Tanggal</th>
                  <th style="width: 15%">Sumber</th>
                  <th style="width: 15%">Tipe Transaksi</th>
                  <th style="width: 15%">Keterangan</th>
                  <th style="width: 15%!important">Mutasi</th>
                  <th style="width: 8%">Tipe</th>
                  <th style="width: 15%!important">Saldo</th>
                  <th style="width: 10%">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(value, index) in listData"
                  :key="index"
                  :style="{
                    color:
                      value.show_rekonsiliasi == 'tidak'
                        ? '#3CB778'
                        : value.catatan_rekonsiliasi ? '#FF3D3D' : '#000000',
                    fontWeight: 500
                  }"
                >
                  <td style="cursor: default;">{{ convertDate(value.tanggal) }}</td>
                  <td @click="detailRoute(value)">{{ value.sumber }}</td>
                  <td style="cursor: default;">{{ value.nama_tipe_transaksi }}</td>
                  <td style="cursor: default;">{{ value.keterangan ? value.keterangan : "-" }}</td>
                  <td class="text-right" style="cursor: default;">{{ formatMoney(value.mutasi) }}</td>
                  <td style="cursor: default;">{{ value.tipe == "debit" ? "Dr" : "Kr" }}</td>
                  <td class="text-right" style="cursor: default;">{{ formatMoney(value.saldo) }}</td>
                  <td>
                    <div class="wrapper-aksi" v-if="value.show_rekonsiliasi != 'tidak'">
                      <div class="detail-aksi" :id="`detail${index}`">
                        <div
                          v-if="value.show_rekonsiliasi != 'tidak' && !value.catatan_rekonsiliasi"
                          class="d-flex mb-2 align-items-center"
                          @click="setMutasi('cancel', value, `detail${index}`)"
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 4.5L4.5 13.5"
                              stroke="#FF3D3D"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4.5 4.5L13.5 13.5"
                              stroke="#FF3D3D"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <div>Mutasi Bank</div>
                        </div>
                        <div
                          v-if="value.show_rekonsiliasi != 'tidak'"
                          class="d-flex mb-2 align-items-center"
                          @click="setMutasi('', value, `detail${index}`)"
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 4.5L6.75 12.75L3 9"
                              stroke="#3CB778"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <div>Mutasi Bank</div>
                        </div>
                        <div
                          class="d-flex align-items-center"
                          v-if="value.catatan_rekonsiliasi"
                          @click="
                            setMutasi('showCatatan', value, `detail${index}`)
                          "
                        >
                          <svg
                            class=""
                            width="18"
                            height="18"
                            viewBox="0 0 31 31"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.74935 2.58203V1.29036V2.58203ZM5.16602 5.16536H3.87435H5.16602ZM5.16602 25.832H3.87435H5.16602ZM18.0827 1.29036H7.74935V3.8737H18.0827V1.29036ZM7.74935 1.29036C6.72164 1.29036 5.73601 1.69862 5.00931 2.42533L6.836 4.25202C7.07824 4.00978 7.40678 3.8737 7.74935 3.8737V1.29036ZM5.00931 2.42533C4.28261 3.15203 3.87435 4.13765 3.87435 5.16536H6.45768C6.45768 4.82279 6.59377 4.49425 6.836 4.25202L5.00931 2.42533ZM3.87435 5.16536V25.832H6.45768V5.16536H3.87435ZM3.87435 25.832C3.87435 26.8597 4.28261 27.8454 5.00931 28.5721L6.836 26.7454C6.59377 26.5031 6.45768 26.1746 6.45768 25.832H3.87435ZM5.00931 28.5721C5.73601 29.2988 6.72163 29.707 7.74935 29.707V27.1237C7.40678 27.1237 7.07824 26.9876 6.836 26.7454L5.00931 28.5721ZM7.74935 29.707H23.2493V27.1237H7.74935V29.707ZM23.2493 29.707C24.2771 29.707 25.2627 29.2988 25.9894 28.5721L24.1627 26.7454C23.9205 26.9876 23.5919 27.1237 23.2493 27.1237V29.707ZM25.9894 28.5721C26.7161 27.8454 27.1243 26.8597 27.1243 25.832H24.541C24.541 26.1746 24.4049 26.5031 24.1627 26.7454L25.9894 28.5721ZM27.1243 25.832V5.16537H24.541V25.832H27.1243ZM23.2493 1.29036H18.0827V3.8737H23.2493V1.29036ZM27.1243 5.16537C27.1243 3.02526 25.3895 1.29036 23.2493 1.29036V3.8737C23.9627 3.8737 24.541 4.452 24.541 5.16537H27.1243Z"
                              fill="#898989"
                            />
                            <path
                              d="M21.9583 15.5H15.5"
                              stroke="#898989"
                              stroke-width="2.58333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.3327 15.5H9.04102"
                              stroke="#898989"
                              stroke-width="2.58333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M21.9583 21.957H15.5"
                              stroke="#898989"
                              stroke-width="2.58333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.3327 21.957H9.04102"
                              stroke="#898989"
                              stroke-width="2.58333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M21.9583 9.04297H18.7292H15.5"
                              stroke="#898989"
                              stroke-width="2.58333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M10.3327 9.04297H9.68685H9.04102"
                              stroke="#898989"
                              stroke-width="2.58333"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <div>Lihat catatan</div>
                        </div>
                      </div>
                    </div>

                    <svg
                      v-if="value.show_rekonsiliasi != 'tidak' && checkAccess('kas_bank', 'cru_recontiliation_bank')"
                      @click="aksi(`detail${index}`)"
                      class="pointer"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#898989"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                        stroke="#898989"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                        stroke="#898989"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <table-lite :is-static-mode="true" :is-loading="table.isLoading" :columns="table.columns"
                      :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
                      @is-finished="table.isLoading = false"></table-lite> -->
      </section>
    </div>
  </div>
</template>
  
  <script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { cksClient, checkModuleAccess, showAlert } from "../../../helper";
import moment from "moment";
import "moment/locale/id";
import Datepicker from "vue3-date-time-picker";
import { get_AkunList } from "../../../actions/akun_perkiraan";
import {
  get_RekonsiliasiList,
  post_RekonsiliasiSave,
} from "../../../actions/rekonsiliasi";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      listData: [],
      id_company: cksClient().get("_account").id_company,
      search: "",
      dataTable: "",
      labelPage: "Rekonsiliasi Bank",
      isSearch: false,
      isSubmit: false,
      OptionAccount: [],
      OptionStatus: [
        {
          id: 'all',
          text: 'Semua',
        },
        {
          id: 'mutasi',
          text: "Ada di mutasi"
        },
        {
          id: "tidak",
          text: "Tidak ada di mutasi"
        }
      ],
      formFilter: {
        id_akun: "",
        date: [
          moment().subtract(1, "month").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        status: 'all',
      },
      formData: {
        no_jurnal_detail: "",
        catatan_rekonsiliasi: "",
        show_rekonsiliasi: "",
        tipe: "",
        detailcatatan: false
      },
    };
  },

  created() {
    this.getKasBank();
    this.setTable();
    //   this.getList();
  },

  mounted() {
    this.tooltip();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    aksi(index) {
      if (!$(`.detail-aksi#${index}`).hasClass("active")) {
        $(".detail-aksi").not(`#${index}`).removeClass("active");
        $(`.detail-aksi#${index}`).addClass("active");
      } else {
        $(".detail-aksi").removeClass("active");
      }

      $(document).mouseup(function (e) {
        var container = $(`.detail-aksi#${index}`); // Replace "yourDivId" with the ID of your div
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          container.removeClass("active");
        }
      });
    },
    detailRoute(data){
      $('[data-toggle="tooltip"]').tooltip("hide");
      if (data.tipe_transaksi == "1") {
        this.$router.push({
          name: 'DetailIncome',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "2") {
        this.$router.push({
          name: 'DetailSpending',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "4" || data.tipe_transaksi == "27" || data.tipe_transaksi == "30") {
        this.$router.push({
          name: 'DetailAsset',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "6") {
        this.$router.push({
          name: 'DetailAccountPayable',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "7") {
        this.$router.push({
          name: 'DetailCredit',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "8") {
        this.$router.push({
          name: 'DetailAccountPayable',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "9") {
        this.$router.push({
          name: 'DetailCredit',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "11") {
        this.$router.push({
          name: 'DetailCredit',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "13") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ?  'DetailPO' : 'DetailPOSupply',
          params: {
            kode: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "14") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ? 'DetailReception' : 'DetailReceptionSupply',
          params: {
            kode: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "15") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ? 'DetailOpname'  : 'DetailOpnameSupply',
          params: {
            kode: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "16") {
        this.$router.push({
          name: 'DetailEmployeeCash',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "17") {
        this.$router.push({
          name: 'DetailEmployeeCash',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "18") {
        var nameRoute = 'DetailSalary';
        if (data.tipeNilai == 'bonus') {
          nameRoute = 'DetailBonus'
        } else if (data.tipeNilai == 'thr') {
          nameRoute = 'DetailTHR'
        } else if (data.tipeNilai == 'man power') {
          nameRoute = 'DetailManPower'
        }
        this.$router.push({
          name: nameRoute,
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "21") {
        this.$router.push({
          name: 'DetailReimburse',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "22") {
        this.$router.push({
          name: 'DetailAssetCheck',
          params: {
            id: data.sumber,
          },
        });
      }else if (data.tipe_transaksi == "23") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ? 'DetailInventorySpending' : 'DetailStockSpending',
          params: {
            id: data.sumber,
          },
        });
      }else if (data.tipe_transaksi == "26" || data.tipe_transaksi == '35') {
        this.$router.push({
          name: 'DetailPaymentOrder',
          params: {
            id_hutang: data.sumber,
          },
        });
      } else if (data.tipe_transaksi == "28") {
        this.$router.push({
          name: 'EditOpex',
          params: {
            id_hutang: data.sumber,
          },
        });
      } else {
        this.$router.push({
          name: "DetailGeneralJournal",
          params: {
            id: data.sumber,
          },
        });
      }
    },
    setMutasi(tipe = "", data, index) {
      this.aksi(index);
      this.formData.no_jurnal_detail = data.no_jurnal_detail;
      this.formData.show_rekonsiliasi = data.show_rekonsiliasi;
      this.formData.catatan_rekonsiliasi = data.catatan_rekonsiliasi;
      this.formData.tipe = tipe;
      if (tipe == "cancel") {
        this.formData.detailcatatan = false;
        $("#addCatatan").modal("show");
      } else if(tipe == 'showCatatan') {
        this.formData.detailcatatan = true;
        $("#addCatatan").modal("show");
      } else if (tipe == "jurnal") {
        this.$router.push({
          name:
            data.tipe_transaksi == 38
              ? "DetailTransferBank"
              : "DetailGeneralJournal",
          params: {
            id: data.sumber,
          },
        });
      } else {
        this.formData.show_rekonsiliasi = "tidak";
        this.onSubmitRekonsiliasi();
      }
    },

    onSubmitRekonsiliasi() {
      if (
        this.formData.tipe == "cancel" &&
        this.formData.catatan_rekonsiliasi
      ) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.isSubmit = true;
            this.postData();
          },
        });
      } else if (this.formData.tipe == "") {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "data rekonsilasi ini akan anda setujui, dan tidak dapat diubah lagi?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.$swal.showLoading();
            this.postData();
          },
        });
      }
    },

    postData() {
      post_RekonsiliasiSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          $("#addCatatan").modal("hide");
          if (this.formData.tipe == "") {
            this.$swal.close();
          }
          this.getList();
          if (res.is_success) {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: "Data berhasil disimpan",
            });
          } else {
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }
        },
        () => {
          if (this.formData.tipe == "") {
            this.$swal.close();
          }
          this.isSubmit = false;
          $("#addCatatan").modal("hide");
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    formatRange(date) {
      if (date[0] && date[1]) {
        return this.convertDateRange(date[0], date[1]);
      }
      return "";
    },

    convertDateRange(date_start, date_end) {
      var start = moment(date_start);
      var end = moment(date_end);
      if (start.format("YYYY-MM") == end.format("YYYY-MM") && start.isValid()) {
        return `${start.format("DD")} s/d ${end.format("DD MMM YYYY")}`;
      } else if (
        start.format("YYYY") == end.format("YYYY") &&
        start.isValid()
      ) {
        return `${start.format("DD MMM")} s/d ${end.format("DD MMM YYYY")}`;
      } else {
        return `${start.format("DD MMM YYYY")} s/d ${end.format(
          "DD MMM YYYY"
        )}`;
      }
    },

    formatMoney(a) {
      if (a > 0 || a < 0) {
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return 0;
    },

    getKasBank() {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "1",
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (
                parseInt(element.max_subLevel) === parseInt(element.sub_level)
              ) {
                data.push({
                  id: element.id,
                  text: `${element.nama}`,
                  no: element.id,
                  type: element.tipe,
                  // sub_level: parseInt(element.sub_level),
                  sub_level: 0,
                  disabled:
                    parseInt(element.max_subLevel) ===
                    parseInt(element.sub_level)
                      ? false
                      : true,
                });
              }
            }
          }

          this.OptionAccount = data;
        }
      );
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    convertDate(string) {
      return moment(string).format("DD MMM YYYY");
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail() {
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    setTable() {
      if (this.dataTable) {
        this.dataTable.destroy();
        this.dataTable = "";
      }

      setTimeout(() => {
        this.dataTable = $("#tableRekonsiliasiBank").DataTable({
          info: false,
          autoWidth: false,
          language: {
            emptyTable: "Tidak ada data",
          },
          order: [],
        });
        this.tooltip();
      }, 1000);
    },
    getList() {
      if (this.formFilter.id_akun && this.formFilter.date.length > 0) {
        this.$swal.showLoading();
        get_RekonsiliasiList(
          {
            id_company: this.id_company,
            id_akun: this.formFilter.id_akun,
            date_start: this.formFilter.date[0],
            date_end: this.formFilter.date[1],
            status: this.formFilter.status
          },
          (res) => {
            this.listData = [];
            this.listData = res.list;
            this.$swal.close();
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.setTable();
          },
          () => {
            this.$swal.close();
          }
        );
      }
    },
  },
};
</script>
  
  <style scoped>
button.btn-add {
  border: none !important;
}

tbody tr td {
  word-wrap: anywhere;
  position: relative;
}
.wrapper-aksi {
  position: relative;
}
.detail-aksi {
  line-height: 20px;
  top: -73px;
  padding: 10px;
  display: none;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  font-size: 13px;
  border: 1px solid #f3f3f3;
  color: #000000;
  left: -113px;
}
.detail-aksi svg {
  margin-right: 10px;
}

.detail-aksi.active {
  display: block;
}
</style>
  
  <style scoped src="../../../assets/css/style.css"></style>
  
  <style scoped src="../../../assets/css/custom.css"></style>
  