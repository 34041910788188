import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_RekonsiliasiList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REKONSILIASI.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const post_RekonsiliasiSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.REKONSILIASI.SAVE, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_RekonsiliasiList,
  post_RekonsiliasiSave,
}